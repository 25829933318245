.app.dark {
    background-color: #222;
    color: rgb(156, 156, 156);

    .chartGrid {
        stroke: rgba(228, 228, 228, 0.219);
    }

    .table {
        background-color: #222;

        .tableCell {
            color: grey
        }
    }

    .navbar {
        color: #999;
        border-color: #333;


        .search {
            border-color: gray; 
            color: gray;

            input {
                color: gray;
            }
        }        
    }

    .sidebar {
        background-color: #111;
        border-color: #333;

        .top {

            .logo {
                color: #333;
            }
        }

        hr {            
            border-color: #333;
        }

        ul {
            li {
                &:hover {
                    background-color: #333;
                }

                .icon {
                    color: #999;
                }
            }
        }

        input {
            background-color: transparent;
        }
    }

    .datatable {
        .datatableTitle {

            .link {
                color: gray;
                border-color: gray;
            }
        }
        .rowdata {
            color: gray;
            border: none;

            .viewButton, .deleteButton, .cellWithStatus {
                color: gray;
                border: none;
            }
        }
    }

    .newContainer {

        .top {

            h1 {
                color: gray;
            }
        }

        .right {

            .formInput {

                input {
                    border-color: gray;
                    background-color: transparent;
                    color: gray;                    
                }
            }
        }
    }
}